<template>
  <!-- 消息模板 -->
  <div class="template">
    <!-- start 模板列表 -->
    <div class="template-list">
      <!-- 单选按钮菜单栏 -->
      <div class="tabs-box">
        <el-radio-group v-model="tempListCode" @change="getTemplateList">
          <el-radio-button
            v-for="(item, index) in classList"
            :key="index"
            :label="item.Code"
            >{{ item.Name }}</el-radio-button
          >
        </el-radio-group>

        <!-- 导入预设模板按钮 -->
        <el-button
          style="margin-top: 20px;"
          type="primary"
          plain
          size="small"
          :disabled="importDisabled"
          @click="importTemplates"
          >导入预设模板</el-button
        >
      </div>

      <!-- 模板数据 -->
      <div class="list-box">
        <div
          class="list-item"
          v-for="(item, index) in templateList"
          :key="index"
        >
          <span>
            <i :class="dynamicClass(item.ApplyType)"></i>
            {{ item.TplContent }}
          </span>
          <i
            class="el-icon-delete"
            v-if="item.LevelCode == 0"
            @click="deleteTemplate(item)"
          ></i>
        </div>
      </div>
    </div>
    <!-- end 模板列表 -->

    <!-- start 增加消息模板 -->
    <div class="add-box">
      <h3>增加消息模板</h3>

      <!-- 模板分类 -->
      <div class="df-row">
        <el-radio-group v-model="tempAddCode" @change="initClassSymbo">
          <el-radio-button
            v-if="item.Code != ''"
            v-for="(item, index) in classList"
            :key="index"
            :label="item.Code"
            >{{ item.Name }}</el-radio-button
          >
        </el-radio-group>
      </div>

      <!-- 消息模板类型 -->
      <div class="df-row">
        <el-radio-group v-model="addApplyType">
          <el-radio :label="0">通用模板</el-radio>
          <el-radio :label="1">微信消息模板</el-radio>
          <el-radio :label="2">短信消息模板</el-radio>
        </el-radio-group>
      </div>

      <div class="df-row" v-if="tempAddCode == 'ExpeTracking'">
        <el-input
          v-model="templateTitle"
          placeholder="请输入模板标题"
          clearable
        ></el-input>
      </div>

      <!-- 文本域 -->
      <div class="df-row">
        <el-input
          id="Textarea"
          ref="Textarea"
          v-model="templateContent"
          :minlength="0"
          :maxlength="500"
          show-word-limit
          :rows="8"
          type="textarea"
        ></el-input>
      </div>

      <!-- 添加按钮 -->
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="small"
        :disabled="submitDisabled"
        @click="addTemplate"
        >增加</el-button
      >

      <!-- 特殊符号列表 -->
      <div class="symbol-list">
        <div
          class="symbol-item"
          v-for="(item, index) in templateSymbolList"
          :key="index"
        >
          <span class="symbol" @click="onInsetSymbol(item)">{{
            item.Value
          }}</span>
          <span class="content">{{ item.Desc }}</span>
        </div>
      </div>
    </div>
    <!-- end 增加消息模板 -->
  </div>
</template>

<script>
import Template from "@/api/template.js";

export default {
  data() {
    return {
      tempListCode: "", // 选中查询的模板分类Code
      tempAddCode: "", // 选中增加的模板分类Code
      templateTitle: "", // 模板标题
      templateContent: "", // 模板内容
      addApplyType: 0, // 消息模板类型
      cursorPosition: 0, // 光标位置
      classList: [], // 模板分类列表
      classMapSymbol: [], // 需要特殊符号的分类列表
      symbolList: [], // 特殊符号列表
      templateList: [], // 模板列表
      templateSymbolList: [], // 选中分类的可选符号列表
      submitDisabled: false, // 提交防抖
      importDisabled: false, // 导入防抖
    };
  },

  async mounted() {
    await this.getClassData();
    this.tempListCode =
      (await this.$route.query.applyType) || this.tempListCode;
    this.tempAddCode = (await this.$route.query.applyType) || this.tempAddCode;
    await this.getTemplateList();
    await this.initClassSymbo();
  },

  methods: {
    // 获取模板分类数据
    async getClassData() {
      try {
        let { data } = await Template.getClassData();
        let { classList, classMapSymbol, symbolList } = data;

        classList.unshift({
          Code: "",
          Name: "全部",
          soft: 0,
        });
        this.classList = classList;
        this.classMapSymbol = classMapSymbol;
        this.symbolList = symbolList;
        this.tempListCode = "";
        this.tempAddCode = classList[1].Code;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取模板列表
    async getTemplateList() {
      try {
        console.log("object :>> ", this.tempListCode);
        let { data } = await Template.getTemplateList({
          class_code: this.tempListCode,
        });

        this.templateList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 初始化选中分类的可选特殊符号列表
    async initClassSymbo() {
      let { classMapSymbol, symbolList, tempAddCode } = this;
      let arr = [];

      await classMapSymbol[tempAddCode].forEach((item) => {
        for (let i = 0; i < symbolList.length; i++) {
          if (symbolList[i].Value == item) {
            arr.push(symbolList[i]);
            break;
          }
        }
      });

      await this.$nextTick(() => {
        this.templateTitle = "";
        this.templateSymbolList = arr;
      });
    },

    // 导入预设模板数据
    async importTemplates() {
      this.importDisabled = true;
      try {
        let { errcode } = await Template.importTemplates();
        if (errcode == 0) {
          this.$message.success("预设模板导出成功");
          this.getTemplateList();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.importDisabled = false;
      }
    },

    // 添加消息模板
    async addTemplate() {
      let {
        tempAddCode,
        templateContent,
        addApplyType,
        tempListCode,
        templateTitle,
      } = this;
      this.submitDisabled = true;
      try {
        let submitData = {
          ClassCode: tempAddCode,
          ApplyType: addApplyType,
          TplContent: templateContent,
          TplName: templateTitle,
        };
        let { errcode } = await Template.addTemplate(submitData);
        if (errcode == 0) {
          this.$message.success("添加成功!");
          this.templateContent = "";
          this.templateTitle = "";
          if (tempListCode == tempAddCode || tempListCode == "") {
            this.getTemplateList();
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.submitDisabled = false;
      }
    },

    // 删除消息模板
    async deleteTemplate(event) {
      try {
        let { errcode } = await Template.deleteTemplate({
          tpl_guid: event.TplGuid,
        });
        if (errcode == 0) {
          this.$message.success("删除成功！");
          this.getTemplateList();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 动态样式分配
    dynamicClass(applyType) {
      let style = "dfWebIcon ";
      switch (applyType) {
        case 1:
          style += "dfWebIcon-weixin wx";
          break;
        case 2:
          style += "dfWebIcon-duanxin msg";
          break;
        default:
          style += "dfWebIcon-xiaoxi cm";
          break;
      }
      return style;
    },

    // 符号插入事件
    onInsetSymbol(event) {
      let dom = document.getElementById("Textarea");
      // 检查浏览器是否支持 selectionRange 属性
      if (typeof dom.selectionStart === "number") {
        this.cursorPosition = dom.selectionStart;
      } else if (document.selection) {
        // 这段代码适用于旧版本的 Internet Explorer
        dom.focus();
        let selection = document.selection.createRange();
        let selectionLength = document.selection.createRange().text.length;
        selection.moveStart("character", -dom.value.length);
        this.cursorPosition = selection.text.length - selectionLength;
      } else {
        this.cursorPosition = 0;
      }

      this.$nextTick(() => {
        let { templateContent } = this;
        this.templateContent =
          templateContent.slice(0, this.cursorPosition) +
          event.Value +
          templateContent.slice(this.cursorPosition);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.template {
  .flex-row();
  height: calc(100vh - 160px);
  padding-top: 20px;

  > div {
    flex: 1;
    height: 100%;
    box-sizing: border-box;
  }

  .template-list {
    .flex-row();
    align-items: unset;

    .tabs-box {
      // width: 100px;

      ::v-deep .el-radio-group {
        .flex-col();

        .el-radio-button,
        .el-radio-button .el-radio-button__inner {
          width: 100%;
        }

        .el-radio-button .el-radio-button__inner {
          border: 1px solid #dcdfe6;
          border-top: 0;
        }

        .el-radio-button:first-child .el-radio-button__inner {
          border-top: 1px solid #dcdfe6;
          border-radius: 4px 4px 0 0;
        }

        .el-radio-button:last-child .el-radio-button__inner {
          border-radius: 0 0 4px 4px;
        }
      }
    }

    .list-box {
      flex: 1;
      margin-left: 20px;
      box-sizing: border-box;
      //   border: 1px solid black;
      overflow-y: auto;

      .list-item {
        .flex-row();
        padding: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #ccc;

        span {
          .flex-row();
          justify-content: unset;
          align-items: unset;
          width: 95%;
          line-height: 24px;
        }

        i {
          margin: auto;
          font-size: 18px;
          cursor: pointer;
        }

        .cm,
        .msg,
        .wx {
          margin: 0;
          font-size: 12px;
          color: white;
          margin-right: 4px;
          cursor: unset;
        }
        .cm::before,
        .msg::before,
        .wx::before {
          padding: 2px 3px;
          border-radius: 4px;
        }
        .cm::before {
          background-color: lightgray;
        }
        .msg::before {
          background-color: orange;
        }
        .wx::before {
          background-color: limegreen;
        }
      }
    }
  }

  .add-box {
    padding-left: 20px;
    overflow-y: auto;

    .symbol-list {
      margin-top: 10px;

      .symbol-item {
        .flex-row();
        line-height: 26px;

        .symbol {
          display: block;
          width: 120px;
          color: #409eff;
          cursor: pointer;
        }

        .content {
          color: #999;
        }
      }
    }
  }

  ::v-deep .el-radio-group {
    .el-radio-button .el-radio-button__inner {
      padding: 10px;
    }
  }

  .scrollbar();
}
</style>
